import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import { useIntl } from "gatsby-plugin-intl"

const IndexPage = ({ data, pageContext }) => {
  // const { node_env } = pageContext
  const intl = useIntl()

  return (
    <Layout>
      {intl.locale === "en" && (
        <SiteMetadata title="MandW Entertainment"
                      description="Portfolio of MandW Entertainment"
                      image={data.social.publicURL}
                      slug={""} />
      )}
      {intl.locale === "ja" && (
        <SiteMetadata title="MandW Entertainment"
                      description="MandW Entertainmentのポートフォリオ"
                      image={data.social.publicURL}
                      slug={""} />
      )}

      <Hero />

      <div className="bg-gray-100 py-12 lg:py-16">
        {/*{node_env}*/}
        {data.portfolio && data.portfolio.nodes.length > 0 ? (
          <Cards items={data.portfolio.nodes} />
        ) : (
          <div className="container">No articles found.</div>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery($node_env: String, $language: String) {
    portfolio: allContentfulPortfolio(
      filter: { env: { eq: $node_env }, node_locale: { eq: $language } }
      sort: { fields: sort, order: DESC }
    ) {
      nodes {
        ...PortfolioCard
      }
    }
    social: file(relativePath: { eq: "social.png" }) {
      publicURL
    }
  }
`
