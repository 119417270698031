import React from "react"
import { useIntl } from "gatsby-plugin-intl"

const Hero = () => {
  const intl = useIntl()

  return (
    <div className="container py-12 lg:pb-16">
      <h2 className="text-3xl sm:text-4xl font-extrabold leading-tight tracking-tight text-gray-900">
        {intl.formatMessage({ id: "landing-text" })}
        {/* <span role="img" aria-label="waving hand">
        👋
      </span> */}
        <br />
        <span className="text-blue-600">
        {intl.formatMessage({ id: "landing-blue-text" })}
      </span>
      </h2>
    </div>
  )
}


export default Hero
